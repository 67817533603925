@import "./../../common/styles";

* {
  margin: 0px;
  padding: 0px;
}

/* CSS 滚动条样式 STAR */

::-moz-selection,
::selection {
  background: #007aff;
  color: white;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  background: rgba(0, 0, 0, 0.05);
}

/* CSS 滚动条样式 END */

body {
  font-size: 13px;
  background-color: #fff;
  font-family: "Microsoft YaHei", Arial, sans-serif;
  /* for ie */
  font: 13px/1 Tahoma, "\5FAE\8F6F\96C5\9ED1", sans-serif, Arial, Helvetica, "\5b8b\4f53";
  /* 用 ascii 字符表示，使得在任何编码下都无问题 */
}

body {
  background-color: #000;
  min-width: 1200px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}

body,
button,
input,
select,
textarea {
  /* for ie */
  font: 13px/1 Tahoma, "\5FAE\8F6F\96C5\9ED1", sans-serif, Arial, Helvetica, "\5b8b\4f53";
}

ul,
li {
  list-style: none;
}

/* 重置列表元素 */

a {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline;
  }
  &:visited,
  &:link {
    text-decoration: none;
  }
  cursor: pointer;
}

img {
  border: none;
}

/* img 搭车：让链接里的 img 无边框 */

button,
input,
select,
textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */

/* 注：optgroup 无法扶正 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置表格元素 */

body {
  min-width: 1000px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none !important;
}

.greyline {
  height: 1px;
  background-color: #dddddd;
  margin: 20px 0 0 66px;
  width: 228px;
}

.blueline {
  height: 2px;
  background-color: #2161b3;
}

.g_glear {
  clear: both;
}

.g_w1 {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  margin-top: 0;
}

@import "components/header";
@import "components/sidebar";
@import "components/footer";
@import "components/page-index";

a {
  text-decoration: none;
  i {
    text-decoration: none;
    color: #e09e01;
  }
}

p {
  margin: 0;
  padding: 0;
}

table,
th {
  border: none;
  padding: 10px;
}

table,
td {
  border: none;
  padding: 10px;
  background-color: none;
}

.main-wrapper {
  width: 1000px;
  max-width: 1048px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

/* Bottom 3 Section Starts Here */

.service-list {
  background: url(../img/service_bg.jpg) no-repeat;
  background-position: center;
  background-color: #f9ebeb;
}

.servicelist-wrapper {
  display: inline-block;
  padding: 35px 0;
  height: 318px;
  box-sizing: border-box;
  width: 100%;
}

.slist-box {
  width: calc((99% - 100px) / 3);
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 100px;
  &:not(:last-child) {
    content: "";
    margin-right: 50px;
  }
}

.sl-title {
  top: 0;
  display: block;
  height: 36px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f47170;
  .ch {
    font-size: 15pt;
    color: #eb2523;
    display: inline-block;
    font-weight: 600;
    border-bottom: 4px solid #eb2523;
    height: 34px;
  }
  .en {
    font-size: 8pt;
    color: #f47170;
    display: inline-block;
  }
}

/* Service Advantages Section Starts Here */

.sl1-title {
  top: 0;
  display: block;
  height: 40px;
  margin-bottom: 10px;
}

.sl1-deposit,
.sl1-withdraw {
  color: #717070;
  font-size: 10.5pt;
  width: 100%;
  display: block;
  margin-top: 30px;
  height: 80px;
}

.deposit-left {
  float: left;
  display: inline-block;
}

.deposit-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.dp-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.dp-title,
.wd-title {
  font-size: 11pt;
  margin: 2px 0;
  color: #827a7a;
}

.dp-time,
.wd-time {
  font-size: 9pt;
  color: #827a7a;
}

.dp-no,
.dp-sec {
  display: inline-block;
}

.dp-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.dp-bargrey {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  overflow: hidden;
}

.dp-barred {
  width: 0;
  height: 10px;
  border-radius: 20px;
  background-color: #eb2523;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
  &.active {
    width: 50%;
  }
}

.withdraw-left {
  float: left;
  display: inline-block;
}

.withdraw-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.wd-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.wd-no,
.wd-sec {
  display: inline-block;
}

.wd-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.wd-bargrey {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  overflow: hidden;
}

.wd-barred {
  width: 0;
  height: 10px;
  border-radius: 20px;
  background-color: #ffa200;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
  &.active {
    width: 70%;
  }
}

/* Mobile Terminal Downloads Starts Here */

.sl2-title {
  padding-bottom: 10px;
  color: #716f6f;
}

.sl2-text {
  font-size: 10.5pt;
  color: #717070;
  line-height: 17pt;
  margin-top: 30px;
}

.sl2-img {
  background: url(../img/terminal_download.png) no-repeat;
  width: 245px;
  height: 70px;
  margin-top: 50px;
  position: relative;
}

.appdl {
  width: 245px;
  height: 70px;
  position: absolute;
}

/* Help Center Section Starts Here */

.sl3-text-wrapper {
  display: block;
  font-size: 10pt;
  color: #717070;
  height: 100px;
  line-height: 16pt;
  margin-top: 30px;
}

.sl3-left {
  float: left;
  display: inline-block;
  width: 100px;
  padding-right: 30px;
  a {
    display: block;
    padding-bottom: 20px;
    color: #717070;
  }
}

.sl3-star {
  background: url(../img/star.png) no-repeat;
  height: 11px;
  width: 11px;
  display: inline-block;
  padding-right: 12px;
}

/* Homepage Footer Starts Here */

.foot {
  background-color: #191919;
  text-align: center;
  height: 227px;
  background-image: url(../img/footer_bg.jpg);
  background-repeat: repeat-x;
}

.footer {
  background-color: #000;
  height: 61px;
}

.foot-text1 {
  background: url(../img/providers.jpg) no-repeat;
  padding: 0;
  height: 100px;
  background-position: center;
  display: block;
}

.foot-text2 {
  color: #fff;
  font-size: 9.5pt;
  padding: 0;
  height: 75px;
  line-height: 66px;
  display: block;
}

.ft-browser {
  display: inline-block;
  font-size: 12pt;
}

.foot-text3 {
  color: #fff;
  font-size: 10pt;
  padding: 0;
  height: 50px;
  line-height: 46px;
  display: block;
}

.footer-text {
  background: url(../img/footer.jpg) no-repeat;
  padding: 0;
  height: 61px;
  background-position: center;
  display: block;
}

.ft-divider {
  background: url(../img/footer_divider.png) no-repeat;
  padding: 0;
  height: 1px;
  background-position: center;
  clear: both;
}

.ft-bs-img {
  background: url(../img/browser.png) no-repeat;
  width: 300px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

/* Subpage Layout Starts Here */

.subpage-body {
  background-color: #fff;
  height: auto;
  padding-bottom: 30px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  p {
    color: #fff;
  }
}

.sub-header {
  height: 140px;
  padding: 47px 0;
  box-sizing: border-box;
}

.sub-title,
.sub-desc {
  display: block;
}

.sub-content {
  height: auto;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.sub-content-wrapper {
  background-color: #fff;
  height: 360px;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
}

/* Register Starts Here */

.subpage-body.registration {
  background: url(../img/registration_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-content.registration {
  background-color: #faeeee;
  border: 1px solid #686868;
}

.sub-header.registration {
  height: 125px;
  width: 1000px;
  margin-bottom: 20px;
  padding: 0;
}

.reg-login-btn {
  display: inline-block;
  background: url(../img/button_reg.png) no-repeat;
  height: 113px;
  width: 209px;
  margin-top: 13px;
  vertical-align: middle;
  position: relative;
  a {
    position: absolute;
    width: 97%;
    height: 95%;
    top: 6px;
  }
}

.reg-banner {
  display: inline-block;
  background: url(../img/banner_reg.png) no-repeat;
  height: 125px;
  width: 786px;
  margin-top: 1px;
  vertical-align: middle;
}

.title-reg {
  font-size: 44pt;
  color: #a2a2a2;
  font-weight: 300;
  vertical-align: top;
  letter-spacing: 3px;
  display: inline-block;
}

.reg-steps {
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #efe8e8;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.reg-steps-box {
  &.red {
    border: 1px solid #eeb958 !important;
    background: rgb(144, 19, 19);
    background: linear-gradient(to right, rgba(144, 19, 19, 1) 0%, rgba(156, 46, 46, 1) 100%);
  }
  display: inline-block;
  vertical-align: middle;
  width: calc(100% / 3);
  text-align: center;
  border: 1px solid #c2c2c2;
  line-height: 32pt;
  float: left;
  box-sizing: border-box;
  position: relative;
  &:not(:first-child) {
    border-left: 0;
  }
  &:not(:last-child) {
    border-left: 0;
    &:before {
      content: "";
      width: 17px;
      height: 30px;
      position: absolute;
      background-color: #efe8e8;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      top: 6px;
      right: -18px;
      border: 1px solid #c2c2c2;
      border-left: none;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 5px solid #978e8e;
      border-top: 6px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid transparent;
      top: 16px;
      right: -15px;
    }
  }
  &.red:not(:last-child) {
    &:before {
      content: "";
      width: 17px;
      height: 30px;
      position: absolute;
      background-color: #9c2e2e;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      top: 6px;
      right: -17px;
      border: 1px solid #eeb958;
      border-left: none;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 5px solid #fff;
      border-top: 6px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid transparent;
      top: 16px;
      right: -15px;
    }
  }
}

.steps-no {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: #978e8e;
}

.steps-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 10.5pt;
  color: #978e8e;
}

.steps-no.red,
.steps-title.red {
  color: #fff;
}

.reg-form {
  height: auto;
  padding: 40px 20px 0 20px;
}

.rform {
  &.row {
    height: 50px;
    line-height: 50px;
    padding-bottom: 5px;
  }
  &.col1 {
    width: 150px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font-size: 9pt;
    color: #eb2523;
  }
  &.col2 {
    width: 280px;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    input {
      box-sizing: border-box;
      width: 90%;
      height: 30px;
      line-height: 30px;
      background-color: transparent;
      -webkit-appearance: none;
      border-radius: 5px;
      outline: none;
      border: 1px solid #978e8e;
      padding-left: 5px;
    }
  }
  &.col3 {
    width: calc(99% - 150px - 290px);
    display: inline-block;
    vertical-align: middle;
    font-size: 9pt;
  }
  &.row {
    .button {
      width: 115px;
      left: 0;
      right: 0;
      margin: auto;
      height: 30px;
      font-size: 10pt;
      display: block;
      margin-top: 20px;
      border: 0px solid transparent;
      color: white;
      cursor: pointer;
    }
  }
  .verify-code {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    bottom: 1px;
  }
}

/* Promotion Starts Here */

.subpage-body.promo {
  min-height: calc(100vh - 48px - 71px - 490px - 227px - 61px);
  background: url(../img/promo_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-header.promo {
  height: 56px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
  padding: 0;
}

.sub-content.promo {
  background-color: rgba(0, 0, 0, 0.7);
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.promo-menu-links {
  &:hover div {
    color: #fff;
  }
  &.active div {
    color: #fff;
    pointer-events: none;
  }
  color: #000;
  display: inline-block;
  width: 100px;
  height: 56px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    background-color: #ce3333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
    opacity: 0;
    transform: skew(-20deg) translateX(-10%);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }
  &.active:before,
  &:hover:before {
    opacity: 1;
    transform: skew(-20deg) translateX(0);
  }
}

.promo-links {
  color: #b62929;
  font-size: 12.5pt;
  text-align: center;
  position: absolute;
  left: 18px;
  right: 0;
  line-height: 56px;
  z-index: 2;
}

.promo-menu-links div {
  &.active {
    pointer-events: none;
    color: #fff;
  }
  &:hover {
    color: #fff;
  }
}

.promo-title {
  font-size: 24pt;
}

.promo-box {
  display: block;
  width: 100%;
  border: 1px solid #999999;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: white;
  .sub-content-item-header {
    min-height: 180px;
    max-height: 190px;
    overflow: hidden;
    display: flex;
    align-items: center;
    h3 {
      margin: 10px 0;
    }
    .banner {
      width: 100%;
      height: auto;
    }
  }
  .sub-content-item-content {
    display: none;
  }
}

.promo-menu {
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding: 0 20px;
}

/* FAQ Starts Here */

.sub-title.faq {
  background: url(../img/faq_title.png) no-repeat;
  width: 98px;
  height: 25px;
}

.sub-desc.faq {
  background: url(../img/faq_desc.png) no-repeat;
  width: 404px;
  height: 14px;
  margin-top: 7px;
}

.subpage-body.faq {
  min-height: calc(100vh - 48px - 71px - 227px - 61px);
  background: url(../img/faq_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-content.faq {
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 1px;
}

.sub-menu ul li {
  list-style: none;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  border-right: none;
}

.sub-body ul li {
  list-style: none;
}

.sub-content .sub-menu {
  float: left;
  width: 200px;
  .itms {
    width: 198px;
    height: 58px;
    border: 1px solid #fff;
    border-right: 1px solid #ffc600;
    position: relative;
    margin-bottom: -1px;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    &.active {
      margin-top: 0;
      border: 1px solid #ffc600;
      border-right: 1px solid #391111;
      z-index: 9;
      color: #ffc600;
    }
  }
}

.sprite {
  background: url(../img/faq_sprite.png) no-repeat top left;
  width: 33px;
  height: 24px;
  float: left;
  margin-top: 15px;
  margin-left: 30px;
}

.itmstxt {
  font-size: 16px;
  float: left;
  line-height: 57px;
}

.sub-content {
  .sub-menu .itms {
    .icon1 {
      background-position: 0 0;
    }
    &.active .icon1 {
      background-position: 0 -25px;
    }
    .icon2 {
      background-position: 0 -50px;
    }
    &.active .icon2 {
      background-position: 0 -75px;
    }
    .icon3 {
      background-position: 0 -101px;
    }
    &.active .icon3 {
      background-position: 0 -125px;
    }
    .icon4 {
      background-position: 0 -150px;
    }
    &.active .icon4 {
      background-position: 0 -175px;
    }
    .icon5 {
      background-position: 0 -200px;
    }
    &.active .icon5 {
      background-position: 0 -225px;
    }
    .icon6 {
      background-position: 0 -250px;
    }
    &.active .icon6 {
      background-position: 0 -275px;
    }
  }
  .sub-body {
    float: left;
    width: calc(100% - 201px);
    margin-left: -3px;
    border: 1px solid #ffc600;
    color: #fff;
    display: none;
    min-height: 412px;
  }
}

.faq-title {
  font-size: 19pt;
  font-weight: 600;
  padding: 18px 35px 0;
}

.faq-text {
  font-size: 11pt;
  line-height: 20pt;
  padding-bottom: 3px;
}

/* Mobile Betting Page Starts Here */

.subpage-body.mobilebet {
  background: url(../img/mobilebet_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 0;
}

.sub-content.mobilebet {
  background-color: transparent;
  height: 800px;
  position: relative;
  overflow: auto;
  padding: 40px 0 0 0;
  box-sizing: border-box;
}

.left-col1 {
  background: url(../img/mobile.png) no-repeat;
  width: 534px;
  height: 661px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-top: 80px;
  position: absolute;
  left: -80px;
}

.right-col1 {
  float: right;
  width: 530px;
  display: inline-block;
  vertical-align: middle;
  height: 620px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  margin-top: 60px;
  position: relative;
}

.play-icon {
  background: url(../img/play.gif) no-repeat;
  width: 84px;
  height: 84px;
  left: -40px;
  top: -28px;
  position: absolute;
}

.btn-play {
  width: 84px;
  height: 84px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.mobile-title {
  background: url(../img/mb_title.png) no-repeat;
  width: 421px;
  height: 28px;
  display: inline-block;
}

.right-col1-text2 {
  font-size: 11pt;
  margin: 5px 0;
}

.right-col1-img {
  background: url(../img/mb_advantages.png) no-repeat;
  width: 507px;
  height: 136px;
  margin: 30px 20px;
}

.right-col1-text3 {
  font-size: 12.5pt;
  padding-bottom: 25px;
  position: relative;
  label {
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 82px;
      border-top: 1px solid #fff;
      top: 12px;
      left: 13%;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 82px;
      border-top: 1px solid #fff;
      top: 12px;
      left: 64%;
    }
  }
}

.qrcode-wrapper {
  margin: 20px 0;
}

.right-col1-wrapper {
  display: inline-block;
  margin: 0 10px;
}

.right-col1-android {
  display: inline-block;
  background: url(../img/mb_60cpapp.png);
  width: 192px;
  height: 193px;
  img,
  canvas {
    width: 170px;
    position: absolute;
    top: 97px;
    height: 166px;
    left: 72px;
  }
}

.tx-android,
.tx-ios {
  color: #fff;
  display: block;
  font-size: 11pt;
  margin: 10px;
}

.btn-android {
  background: url(../img/mb_button.png) 0 0;
  width: 180px;
  height: 56px;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
}

.btn-ios {
  background: url(../img/mb_button.png) 180px 0;
  width: 180px;
  height: 56px;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
}

.btn-qr {
  width: 180px;
  height: 56px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.right-col1-ios {
  display: inline-block;
  background: url(../img/mb_60cpapp.png);
  width: 192px;
  height: 193px;
  img,
  canvas {
    width: 170px;
    position: absolute;
    top: 97px;
    height: 166px;
    right: 72px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: none;
  z-index: 999;
  &:target {
    display: block;
  }
}

.popup {
  margin: 80px auto;
  padding: 20px;
  background: #000;
  width: 70%;
  position: relative;
  transition: all 5s ease-in-out;
  margin: 80px auto;
  padding: 20px;
  background: #000;
  width: 70%;
  height: calc(100% - 200px);
  position: relative;
  transition: all 5s ease-in-out;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    z-index: 1;
    &:hover {
      color: #ce3333;
    }
  }
  .content {
    text-align: center;
  }
}

.content .video-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.popup img {
  max-width: 100%;
}

.video-js .vjs-big-play-button {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto;
}

/* Jssor Slider */

/* ssor slider loading skin spin css */

.jssorb032 {
  position: absolute;
  .i {
    position: absolute;
    cursor: pointer;
    .b {
      fill: #fff558;
    }
    &:hover .b {
      fill: #f45b25;
      fill-opacity: 0.6;
    }
  }
  .iav .b {
    fill: #f45b25;
    fill-opacity: 1;
  }
  .i.idn {
    opacity: 0.3;
  }
}

.jssora051 {
  display: block;
  position: absolute;
  cursor: pointer;
  .a {
    fill: none;
    stroke: #fff;
    stroke-width: 360;
    stroke-miterlimit: 10;
  }
  &:hover {
    opacity: 0.8;
  }
  &.jssora051dn {
    opacity: 0.5;
  }
  &.jssora051ds {
    opacity: 0.3;
    pointer-events: none;
  }
}

.daohang {
  width: 120px;
}

.appqr-wrapper {
  z-index: 99999;
  width: 118px;
  .appqrcode {
    width: 118px;
    background-color: #b62929;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    img {
      width: 98px;
      height: 98px;
      background-color: #fff;
      box-sizing: border-box;
      padding: 6px;
    }
  }
}

.appqr img {
  background-color: #fff;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  top: 80px;
  position: absolute;
  left: 14px;
}

.appqr-wrapper .text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  font-style: italic;
  margin-top: 7px;
  font-weight: 600;
}

.appdlurl {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.5px;
}
