/* 顶部工具条 */

.header-links {
  float: right;
  margin-top: 0px;
  line-height: 45px;
  ul {
    color: #fff;
    float: left;
    li {
      float: left;
      margin-right: 20px;
      a {
        color: #fff;
      }
    }
  }
}

/*Top Header Starts Here*/

.top-header {
  height: 48px;
  background-color: #b62929;
  width: 100%;
}

.logo {
  width: 237px;
  height: 60px;
  display: block;
  position: absolute;
  margin-top: 5px;
  img {
    max-height: 100%;
  }
}

.datetime {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 10.5pt;
  color: #fff;
  display: inline-block;
  .more-links {
    padding-left: 12px;
    a {
      text-decoration: none;
      color: #fff;
      position: relative;
      .red-packet {
        position: relative;
        width: 16px;
        height: 22px;
        background-color: #d1333d;
        box-shadow: 0 0 14px #ffc600;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        perspective: 20px;
        transition: all 300ms ease-in-out;
        animation: tada 2s linear infinite alternate;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border-top: 8px solid #f05348;
          border-right: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid transparent;
          transform-style: preserve-3d;
          transform-origin: top;
          transform: rotateX(0);
          transition: transform 300ms ease-in-out;
        }
        &:after {
          content: "$";
          position: absolute;
          top: 48%;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          margin: auto;
          font-size: 6px;
          line-height: 8px;
          text-align: center;
          background-color: #ffc600;
          width: 8px;
          height: 8px;
          transform: translateY(-50%);
          color: #000;
        }
      }
      &:hover .red-packet {
        box-shadow: 0 0 30px #ffc600;
        &:before {
          transform: rotateX(90deg);
        }
      }
    }
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.quick-links {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 9.5pt;
  display: inline-block;
  a:not(:last-child):after {
    content: "|";
    position: absolute;
    color: #fff;
    line-height: 34pt;
    right: -15px;
  }
}

.qlinks {
  color: #fff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

.login-wrapper {
  float: right;
  height: 48px;
  line-height: 48px;
  font-size: 10.5pt;
  color: #fff;
  button {
    color: #fff;
    border: none;
  }
  div {
    a {
      color: #fff;
    }
    margin-left: 5px;
  }
}

.button {
  display: inline-block;
  background: rgb(239, 82, 82);
  background: linear-gradient(to bottom, rgba(239, 82, 82, 1) 0%, rgba(194, 31, 31, 1) 100%);
  width: 60px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  line-height: 30px;
  transition-duration: 400ms;
  transition-timing-function: ease;
  box-sizing: border-box;
  &:hover {
    box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(255, 240, 171, 0.5);
  }
}

.login-wrapper a div {
  color: #fff;
}

.input {
  &.username,
  &.password {
    display: inline-block;
    width: 150px;
    height: 30px;
    line-height: 30px;
    background-color: #6d1919;
    border-radius: 20px;
    border: 1px solid rgba(255, 240, 171, 0.6);
    position: relative;
  }
  &.vcode {
    display: inline-block;
    width: 120px;
    height: 30px;
    line-height: 30px;
    background-color: #6d1919;
    border-radius: 20px;
    border: 1px solid rgba(255, 240, 171, 0.6);
    position: relative;
  }
}

.login-wrapper {
  .input.vcode input {
    background-color: transparent;
    border: 0;
    outline: none;
    height: 28px;
    font-size: 10pt;
    margin-left: 10px;
    color: #fff;
  }
  input {
    background-color: transparent;
    border: 0;
    outline: none;
    height: 28px;
    font-size: 10pt;
    padding-left: 35px;
    color: #fff;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.input {
  &.username {
    display: inline-block;
    color: #fff;
    &:before {
      content: "";
      background-image: url(../img/ico_username.png);
      height: 17px;
      width: 16px;
      position: absolute;
      top: 7px;
      left: 12px;
    }
  }
  &.password {
    &:before {
      content: "";
      background-image: url(../img/ico_password.png);
      height: 17px;
      width: 14px;
      position: absolute;
      top: 7px;
      left: 12px;
    }
    display: inline-block;
  }
}

.verify-code.vcode-login {
  position: absolute;
  right: 5px;
  top: 4px;
  border-radius: 0 20px 20px 0;
  width: 50%;
  cursor: pointer;
}

.btn-pass {
  background-color: #961313;
  border-radius: 5px 20px 20px 5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 50px;
  font-size: 9pt;
  position: absolute;
  right: 5px;
  top: 4px;
  &:hover {
    background-color: #ce3333;
  }
}

/*Main Menu Starts Here*/

.navigation {
  height: 70px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
  border-bottom: 1px solid #000;
}

.main-menu {
  float: right;
  height: 70px;
  /*padding: 24px 0 24px 25px;*/
}

.menu-links {
  &:hover {
    color: #fff;
  }
  &.active {
    color: #fff;
    pointer-events: none;
  }
  color: #000;
  display: inline-block;
  width: 90px;
  height: 70px;
  position: relative;
  cursor: pointer;
  &.vip .ch {
    &:before {
      content: "VIP";
      position: absolute;
      color: #ffc600;
      background-color: #ce3333;
      top: -16px;
      left: -5px;
      border-radius: 4px;
      display: block;
      padding: 1px 4px;
      font-size: 10px;
    }
    &:after {
      content: "";
      position: absolute;
      border-top: 2px solid #ce3333;
      border-right: 4px solid #ce3333;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      left: 8px;
    }
  }
  &:before {
    content: "";
    background-color: #ce3333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transform: skew(20deg) translateX(5%);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    pointer-events: none;
  }
  &:after {
    content: "";
    background-color: #fa7b78;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transform: skew(-20deg) translateX(-5%);
    transform: translateX(-50%);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    pointer-events: none;
  }
  &.active:before,
  &:hover:before {
    opacity: 1;
    transform: skew(20deg) translateX(0);
  }
  &.active:after,
  &:hover:after {
    opacity: 1;
    transform: skew(-20deg) translateX(0);
  }
  .ch {
    font-size: 10.5pt;
    line-height: 10.5pt;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    z-index: 2;
  }
  .en {
    font-size: 7.5pt;
    text-align: center;
    line-height: 7.5pt;
    padding-top: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 2;
  }

  &.menu-links-lottery {
    &.guest {
      & + .menu-links-lottery {
        display: none;
      }
    }
  }
}

/*Navigation Lottery Dropdown Starts Here*/

.lottery-dropdown-wrapper {
  position: absolute;
  top: 74px;
  left: -380px;
  display: none;
}

.menu-links.lottery:hover .lottery-dropdown-wrapper {
  /*display: block;*/
}

.lottery-dropdown {
  width: 650px;
  background-color: rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 10;
  top: 0;
  border-radius: 10px;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(32, 32, 29, 0.5);
  .arrow {
    // content: "";
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: -5px;
    right: 562px;
    z-index: 15;
    box-sizing: border-box;
    transform: rotate(45deg);
    box-shadow: -1px -1px 1px #fff0ab, -1px -1px 2px rgba(255, 240, 171, 0);
  }
  &:before {
    content: "";
    width: 100%;
    height: 12px;
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 15;
    box-sizing: border-box;
  }
}

.lottery-dropdown-title {
  color: #fff;
  font-size: 11pt;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.lottery_guan {
  background: url(../img/icon_guan.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: -3px 0 0 7px;
}


.lottery-dropdown ul {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-wrap: wrap;
  font-size: 10pt;
  text-align: center;
  li {
    border-radius: 20px;
    cursor: pointer;
    flex-basis: calc(100% / 4);
    height: 100px;
    width: 100px;
    margin: 20px 0;
    position: relative;

    .lottery-games {
      height: 100px;
      width: 100px;
      margin: 0 auto 5px;
    }

    &.game-1 .lottery-games {
      background: url('../img/lottery_dropdown.png') -10px -10px;
    }
    &.game-2 .lottery-games {
      background: url('../img/lottery_dropdown.png') -130px -10px;
    }
    &.game-3 .lottery-games {
      background: url('../img/lottery_dropdown.png') -10px -130px;
    }
    &.game-4 .lottery-games {
      background: url('../img/lottery_dropdown.png') -130px -130px;
    }
    &.game-5 .lottery-games {
      background: url('../img/lottery_dropdown.png') -250px -10px;
    }
    &.game-6 .lottery-games {
      background: url('../img/lottery_dropdown.png') -250px -130px;
    }
    &.game-7 .lottery-games {
      background: url('../img/lottery_dropdown.png') -10px -250px;
    }
    &.game-8 .lottery-games {
      background: url('../img/lottery_dropdown.png') -130px -250px;
    }
    &.game-9 .lottery-games {
      background: url('../img/lottery_dropdown.png') -250px -250px;
    }
    &.game-10 .lottery-games {
      background: url('../img/lottery_dropdown.png') -370px -10px;
    }
    &.game-11 .lottery-games {
      background: url('../img/lottery_dropdown.png') -370px -130px;
    }
    &.game-12 .lottery-games {
      background: url('../img/lottery_dropdown.png') -370px -250px;
    }
    &.game-13 .lottery-games {
      background: url('../img/lottery_dropdown.png') -10px -370px;
    }
    &.game-14 .lottery-games {
      background: url('../img/lottery_dropdown.png') -130px -370px;
    }
    &.game-15 .lottery-games {
      background: url('../img/lottery_dropdown.png') -250px -370px;
    }
    &.game-16 .lottery-games {
      background: url('../img/lottery_dropdown.png') -370px -370px;
    }
    &.game-17 .lottery-games {
      background: url('../img/lottery_dropdown.png') -490px -10px;
    }
    &.game-18 .lottery-games {
      background: url('../img/lottery_dropdown.png') -490px -130px;
    }
    &.game-19 .lottery-games {
      background: url('../img/lottery_dropdown.png') -490px -250px;
    }
    &.game-20 .lottery-games {
      background: url('../img/lottery_dropdown.png') -490px -370px;
    }
    &.game-21 .lottery-games {
      background: url('../img/lottery_dropdown.png') -10px -490px;
    }
    &.game-22 .lottery-games {
      background: url('../img/lottery_dropdown.png') -130px -490px;
    }
    &.game-23 .lottery-games {
      background: url('../img/lottery_dropdown.png') -250px -490px;
    }
    &.game-24 .lottery-games {
      background: url('../img/lottery_dropdown.png') -370px -490px;
    }
  }
}
