@charset "UTF-8";
iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

* {
  margin: 0px;
  padding: 0px;
}

/* CSS 滚动条样式 STAR */
::-moz-selection,
::selection {
  background: #007aff;
  color: white;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  background: rgba(0, 0, 0, 0.05);
}

/* CSS 滚动条样式 END */
body {
  font-size: 13px;
  background-color: #fff;
  font-family: "Microsoft YaHei", Arial, sans-serif;
  /* for ie */
  font: 13px/1 Tahoma, "微软雅黑", sans-serif, Arial, Helvetica, "宋体";
  /* 用 ascii 字符表示，使得在任何编码下都无问题 */
}

body {
  background-color: #000;
  min-width: 1200px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}

body,
button,
input,
select,
textarea {
  /* for ie */
  font: 13px/1 Tahoma, "微软雅黑", sans-serif, Arial, Helvetica, "宋体";
}

ul,
li {
  list-style: none;
}

/* 重置列表元素 */
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
a:visited, a:link {
  text-decoration: none;
}

img {
  border: none;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置表格元素 */
body {
  min-width: 1000px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none !important;
}

.greyline {
  height: 1px;
  background-color: #dddddd;
  margin: 20px 0 0 66px;
  width: 228px;
}

.blueline {
  height: 2px;
  background-color: #2161b3;
}

.g_glear {
  clear: both;
}

.g_w1 {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  margin-top: 0;
}

/* 顶部工具条 */
.header-links {
  float: right;
  margin-top: 0px;
  line-height: 45px;
}
.header-links ul {
  color: #fff;
  float: left;
}
.header-links ul li {
  float: left;
  margin-right: 20px;
}
.header-links ul li a {
  color: #fff;
}

/*Top Header Starts Here*/
.top-header {
  height: 48px;
  background-color: #b62929;
  width: 100%;
}

.logo {
  width: 237px;
  height: 60px;
  display: block;
  position: absolute;
  margin-top: 5px;
}
.logo img {
  max-height: 100%;
}

.datetime {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 10.5pt;
  color: #fff;
  display: inline-block;
}
.datetime .more-links {
  padding-left: 12px;
}
.datetime .more-links a {
  text-decoration: none;
  color: #fff;
  position: relative;
}
.datetime .more-links a .red-packet {
  position: relative;
  width: 16px;
  height: 22px;
  background-color: #d1333d;
  box-shadow: 0 0 14px #ffc600;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  perspective: 20px;
  transition: all 300ms ease-in-out;
  animation: tada 2s linear infinite alternate;
}
.datetime .more-links a .red-packet:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 8px solid #f05348;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  transform-style: preserve-3d;
  transform-origin: top;
  transform: rotateX(0);
  transition: transform 300ms ease-in-out;
}
.datetime .more-links a .red-packet:after {
  content: "$";
  position: absolute;
  top: 48%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  margin: auto;
  font-size: 6px;
  line-height: 8px;
  text-align: center;
  background-color: #ffc600;
  width: 8px;
  height: 8px;
  transform: translateY(-50%);
  color: #000;
}
.datetime .more-links a:hover .red-packet {
  box-shadow: 0 0 30px #ffc600;
}
.datetime .more-links a:hover .red-packet:before {
  transform: rotateX(90deg);
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
.quick-links {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 9.5pt;
  display: inline-block;
}
.quick-links a:not(:last-child):after {
  content: "|";
  position: absolute;
  color: #fff;
  line-height: 34pt;
  right: -15px;
}

.qlinks {
  color: #fff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

.login-wrapper {
  float: right;
  height: 48px;
  line-height: 48px;
  font-size: 10.5pt;
  color: #fff;
}
.login-wrapper button {
  color: #fff;
  border: none;
}
.login-wrapper div {
  margin-left: 5px;
}
.login-wrapper div a {
  color: #fff;
}

.button {
  display: inline-block;
  background: rgb(239, 82, 82);
  background: linear-gradient(to bottom, rgb(239, 82, 82) 0%, rgb(194, 31, 31) 100%);
  width: 60px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  line-height: 30px;
  transition-duration: 400ms;
  transition-timing-function: ease;
  box-sizing: border-box;
}
.button:hover {
  box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(255, 240, 171, 0.5);
}

.login-wrapper a div {
  color: #fff;
}

.input.username, .input.password {
  display: inline-block;
  width: 150px;
  height: 30px;
  line-height: 30px;
  background-color: #6d1919;
  border-radius: 20px;
  border: 1px solid rgba(255, 240, 171, 0.6);
  position: relative;
}
.input.vcode {
  display: inline-block;
  width: 120px;
  height: 30px;
  line-height: 30px;
  background-color: #6d1919;
  border-radius: 20px;
  border: 1px solid rgba(255, 240, 171, 0.6);
  position: relative;
}

.login-wrapper .input.vcode input {
  background-color: transparent;
  border: 0;
  outline: none;
  height: 28px;
  font-size: 10pt;
  margin-left: 10px;
  color: #fff;
}
.login-wrapper input {
  background-color: transparent;
  border: 0;
  outline: none;
  height: 28px;
  font-size: 10pt;
  padding-left: 35px;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  border-radius: 20px;
}
.login-wrapper input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.input.username {
  display: inline-block;
  color: #fff;
}
.input.username:before {
  content: "";
  background-image: url(../img/ico_username.png);
  height: 17px;
  width: 16px;
  position: absolute;
  top: 7px;
  left: 12px;
}
.input.password {
  display: inline-block;
}
.input.password:before {
  content: "";
  background-image: url(../img/ico_password.png);
  height: 17px;
  width: 14px;
  position: absolute;
  top: 7px;
  left: 12px;
}

.verify-code.vcode-login {
  position: absolute;
  right: 5px;
  top: 4px;
  border-radius: 0 20px 20px 0;
  width: 50%;
  cursor: pointer;
}

.btn-pass {
  background-color: #961313;
  border-radius: 5px 20px 20px 5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 50px;
  font-size: 9pt;
  position: absolute;
  right: 5px;
  top: 4px;
}
.btn-pass:hover {
  background-color: #ce3333;
}

/*Main Menu Starts Here*/
.navigation {
  height: 70px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
  border-bottom: 1px solid #000;
}

.main-menu {
  float: right;
  height: 70px;
  /*padding: 24px 0 24px 25px;*/
}

.menu-links {
  color: #000;
  display: inline-block;
  width: 90px;
  height: 70px;
  position: relative;
  cursor: pointer;
}
.menu-links:hover {
  color: #fff;
}
.menu-links.active {
  color: #fff;
  pointer-events: none;
}
.menu-links.vip .ch:before {
  content: "VIP";
  position: absolute;
  color: #ffc600;
  background-color: #ce3333;
  top: -16px;
  left: -5px;
  border-radius: 4px;
  display: block;
  padding: 1px 4px;
  font-size: 10px;
}
.menu-links.vip .ch:after {
  content: "";
  position: absolute;
  border-top: 2px solid #ce3333;
  border-right: 4px solid #ce3333;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  left: 8px;
}
.menu-links:before {
  content: "";
  background-color: #ce3333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transform: skew(20deg) translateX(5%);
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  pointer-events: none;
}
.menu-links:after {
  content: "";
  background-color: #fa7b78;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transform: skew(-20deg) translateX(-5%);
  transform: translateX(-50%);
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  pointer-events: none;
}
.menu-links.active:before, .menu-links:hover:before {
  opacity: 1;
  transform: skew(20deg) translateX(0);
}
.menu-links.active:after, .menu-links:hover:after {
  opacity: 1;
  transform: skew(-20deg) translateX(0);
}
.menu-links .ch {
  font-size: 10.5pt;
  line-height: 10.5pt;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 2;
}
.menu-links .en {
  font-size: 7.5pt;
  text-align: center;
  line-height: 7.5pt;
  padding-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 2;
}
.menu-links.menu-links-lottery.guest + .menu-links-lottery {
  display: none;
}

/*Navigation Lottery Dropdown Starts Here*/
.lottery-dropdown-wrapper {
  position: absolute;
  top: 74px;
  left: -380px;
  display: none;
}

.menu-links.lottery:hover .lottery-dropdown-wrapper {
  /*display: block;*/
}

.lottery-dropdown {
  width: 650px;
  background-color: rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 10;
  top: 0;
  border-radius: 10px;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(32, 32, 29, 0.5);
}
.lottery-dropdown .arrow {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: -5px;
  right: 562px;
  z-index: 15;
  box-sizing: border-box;
  transform: rotate(45deg);
  box-shadow: -1px -1px 1px #fff0ab, -1px -1px 2px rgba(255, 240, 171, 0);
}
.lottery-dropdown:before {
  content: "";
  width: 100%;
  height: 12px;
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 15;
  box-sizing: border-box;
}

.lottery-dropdown-title {
  color: #fff;
  font-size: 11pt;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.lottery_guan {
  background: url(../img/icon_guan.png) no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: -3px 0 0 7px;
}

.lottery-dropdown ul {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-wrap: wrap;
  font-size: 10pt;
  text-align: center;
}
.lottery-dropdown ul li {
  border-radius: 20px;
  cursor: pointer;
  flex-basis: 25%;
  height: 100px;
  width: 100px;
  margin: 20px 0;
  position: relative;
}
.lottery-dropdown ul li .lottery-games {
  height: 100px;
  width: 100px;
  margin: 0 auto 5px;
}
.lottery-dropdown ul li.game-1 .lottery-games {
  background: url("../img/lottery_dropdown.png") -10px -10px;
}
.lottery-dropdown ul li.game-2 .lottery-games {
  background: url("../img/lottery_dropdown.png") -130px -10px;
}
.lottery-dropdown ul li.game-3 .lottery-games {
  background: url("../img/lottery_dropdown.png") -10px -130px;
}
.lottery-dropdown ul li.game-4 .lottery-games {
  background: url("../img/lottery_dropdown.png") -130px -130px;
}
.lottery-dropdown ul li.game-5 .lottery-games {
  background: url("../img/lottery_dropdown.png") -250px -10px;
}
.lottery-dropdown ul li.game-6 .lottery-games {
  background: url("../img/lottery_dropdown.png") -250px -130px;
}
.lottery-dropdown ul li.game-7 .lottery-games {
  background: url("../img/lottery_dropdown.png") -10px -250px;
}
.lottery-dropdown ul li.game-8 .lottery-games {
  background: url("../img/lottery_dropdown.png") -130px -250px;
}
.lottery-dropdown ul li.game-9 .lottery-games {
  background: url("../img/lottery_dropdown.png") -250px -250px;
}
.lottery-dropdown ul li.game-10 .lottery-games {
  background: url("../img/lottery_dropdown.png") -370px -10px;
}
.lottery-dropdown ul li.game-11 .lottery-games {
  background: url("../img/lottery_dropdown.png") -370px -130px;
}
.lottery-dropdown ul li.game-12 .lottery-games {
  background: url("../img/lottery_dropdown.png") -370px -250px;
}
.lottery-dropdown ul li.game-13 .lottery-games {
  background: url("../img/lottery_dropdown.png") -10px -370px;
}
.lottery-dropdown ul li.game-14 .lottery-games {
  background: url("../img/lottery_dropdown.png") -130px -370px;
}
.lottery-dropdown ul li.game-15 .lottery-games {
  background: url("../img/lottery_dropdown.png") -250px -370px;
}
.lottery-dropdown ul li.game-16 .lottery-games {
  background: url("../img/lottery_dropdown.png") -370px -370px;
}
.lottery-dropdown ul li.game-17 .lottery-games {
  background: url("../img/lottery_dropdown.png") -490px -10px;
}
.lottery-dropdown ul li.game-18 .lottery-games {
  background: url("../img/lottery_dropdown.png") -490px -130px;
}
.lottery-dropdown ul li.game-19 .lottery-games {
  background: url("../img/lottery_dropdown.png") -490px -250px;
}
.lottery-dropdown ul li.game-20 .lottery-games {
  background: url("../img/lottery_dropdown.png") -490px -370px;
}
.lottery-dropdown ul li.game-21 .lottery-games {
  background: url("../img/lottery_dropdown.png") -10px -490px;
}
.lottery-dropdown ul li.game-22 .lottery-games {
  background: url("../img/lottery_dropdown.png") -130px -490px;
}
.lottery-dropdown ul li.game-23 .lottery-games {
  background: url("../img/lottery_dropdown.png") -250px -490px;
}
.lottery-dropdown ul li.game-24 .lottery-games {
  background: url("../img/lottery_dropdown.png") -370px -490px;
}

/* Floater Starts Here */
.socialnavi {
  position: fixed;
  right: 10px;
  top: 250px;
  overflow: visible;
  z-index: 10;
}

.qq,
.wechat,
.phone,
.livechat,
.chatroom,
.download,
.phoneback,
.download-mac,
.download-windows,
.morse,
.kefuapp {
  border-radius: 38px;
  height: 50px;
  width: 50px;
  margin-bottom: 3px;
  cursor: pointer;
}

.qq {
  background: url(../img/icons/support_qq.png) center;
  background-color: #0099cc;
}
.qq:hover {
  border: 3px solid #7ddfff;
  height: 44px;
  width: 44px;
}

.wechat {
  position: relative;
  background: url(../img/icons/support_wechat.png) center;
  background-color: #009900;
}
.wechat:hover {
  border: 3px solid #9afa9a;
  height: 44px;
  width: 44px;
}
.wechat:hover .sidebar-popout {
  transform: scale(1);
}

.downloadqr {
  background-image: url(../img/icons/download_bg.png);
  position: absolute;
  top: 160px;
  left: -160px;
  width: 158px;
  height: 61px;
  display: none;
}
.downloadqr p {
  margin-top: 13px;
  text-align: center;
  line-height: 17px;
}

.phoneback {
  background: url(../img/icons/phoneback.png) center;
  background-color: #0099cc;
}
.phoneback:hover {
  border: 3px solid #7ddfff;
  height: 46px;
  width: 46px;
}

.phonebackqr {
  background-image: url(../img/icons/download_bg.png);
  position: absolute;
  top: 210px;
  left: -160px;
  width: 158px;
  height: 61px;
  display: none;
}
.phonebackqr p {
  margin-top: 20px;
  text-align: center;
  line-height: 17px;
}

.phone {
  background: url(../img/icons/support_call.png) center;
  background-color: #f0664b;
}
.phone:hover {
  border: 3px solid #ffc8aa;
  height: 44px;
  width: 44px;
}

.livechat {
  background: url(../img/icons/livechat.png) center;
  background-color: #ffae00;
}
.livechat:hover {
  border: 3px solid #ffc8aa;
  height: 44px;
  width: 44px;
}

.chatroom {
  background: url(../img/icons/chatroom.png) center;
  background-color: #feb6a7;
}
.chatroom:hover {
  border: 3px solid #fecac0;
  height: 44px;
  width: 44px;
}

.download {
  background: url(../img/icons/download.png) center;
  background-color: #536bb9;
}
.download:hover {
  border: 3px solid #ffc8aa;
  height: 44px;
  width: 44px;
}

.download-mac {
  background: #ffb82e url(../img/icons/mac_download.png) center;
  position: relative;
}

.download-windows {
  background: #ffb82e url(../img/icons/windows_download.png) center;
  position: relative;
}

.download-mac:before,
.download-windows:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  opacity: 0;
}

.download-mac:hover:before,
.download-windows:hover:before {
  opacity: 1;
}

.morse {
  display: block;
  position: relative;
  background: url(../img/morse-icon.png) center/contain no-repeat;
}
.morse:hover {
  border: 3px solid rgba(255, 255, 255, 0.5);
  height: 44px;
  width: 44px;
}
.morse:hover .morseqr {
  display: block;
}

.morseqr {
  display: none;
  position: absolute;
  height: 165px;
  width: 186px;
  top: 0;
  left: -170px;
}

.specdetail .card_count {
  margin-top: 0px;
}

.kefuapp {
  position: relative;
  background: url(../img/icons/kefuapp.png) center/75% #b75b1f no-repeat;
  border: 3px solid transparent;
  height: 44px;
  width: 44px;
}
.kefuapp:hover {
  border-color: #823a0b;
}
.kefuapp:hover .sidebar-popout {
  transform: scale(1);
}

.sidebar-popout {
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #fff;
  padding: 5px;
  position: absolute;
  right: 115%;
  top: 0;
  text-align: center;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0);
  transform-origin: top right;
}

/* left adv */
.left_adv {
  height: 389px;
  width: 129px;
  position: fixed;
  left: 0;
  top: 220px;
  z-index: 9;
}

.left_adv_img {
  width: 100%;
}

/* right adv */
.rigth_adv {
  height: 250px;
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 999;
}
.rigth_adv img {
  width: 127px;
}

/* footer navi */
.footercontainer {
  width: 1000px;
  height: 238px;
  margin: 0 auto;
}

.footernavi2 {
  height: 120px;
  background-color: #2161b3;
}
.footernavi2 .col4,
.footernavi2 .col5,
.footernavi2 .col6 {
  height: 238px;
  float: left;
}
.footernavi2 .col4 {
  width: 325px;
}
.footernavi2 .col5 {
  width: 338px;
}
.footernavi2 .col6 {
  width: 331px;
}

.footercontent {
  width: 220px;
  height: auto;
  margin-top: 15px;
  margin-left: 85px;
  text-align: left;
}
.footercontent a {
  text-decoration: none;
  color: #bcc9e3;
  line-height: 25px;
  font-size: 13px;
}

.borderright {
  border-right: 1px solid #174f97;
}

.borderleft {
  border-left: 1px solid #4073b4;
}

.bothborder {
  border-left: 1px solid #174f97;
  border-right: 1px solid #4073b4;
}

.footerindex2 {
  padding: 30px 0;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.bank_number {
  margin-top: 0px;
}

.bank_numbertxt {
  margin-top: 26px;
}

#btn-register {
  float: left;
  margin-right: 5px;
  margin-top: 1px;
}

/* banner */
.bannerpromo {
  background-image: url(../img/cash/promotiontile.jpg);
  background-repeat: repeat-x;
}

.promobanner {
  cursor: pointer;
}

.bxslider2,
.bxslider3 {
  height: 160px !important;
}

.coltxt a {
  color: #000;
}

.cci_title {
  height: 20px;
  left: 0px;
  top: 0px;
  color: #111;
  font-size: 15.5px;
  line-height: 18px;
  white-space: nowrap;
  text-shadow: 0 1px 0 #ffffff;
}

.cci_title2 {
  height: auto;
  left: 0px;
  top: 0px;
  color: #111;
  font-size: 15.5px;
  line-height: 20px;
  white-space: nowrap;
  text-shadow: 0 1px 0 #ffffff;
  margin-bottom: 10px;
}

.bxslider4 {
  height: 274px !important;
}

/*Main Banner Starts Here*/
.main-banner {
  height: 490px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.main-banner .swiper-container {
  width: 100%;
  height: 490px;
}
.main-banner .swiper-container .swiper-pagination.banner {
  bottom: 50px;
}
.main-banner .swiper-container .swiper-pagination.banner .swiper-pagination-bullet {
  background-color: #fec52e;
}
.main-banner .swiper-container .swiper-pagination.banner .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}
.main-banner img {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  overflow: hidden;
}
.swiper-slide .img-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px) grayscale(50%);
  z-index: 0;
}
.swiper-slide img {
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

/* News Section Starts Here */
.marquee-wrapper {
  display: flex;
  align-items: center;
}
.marquee-wrapper .info-wrapper {
  width: 100%;
  height: 2.75rem;
  position: relative;
  overflow: hidden;
}
.marquee-wrapper .info-wrapper .info {
  padding-left: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  animation: infoHide 300ms ease;
  animation-fill-mode: both;
}
.marquee-wrapper .info-wrapper .info.active {
  animation: infoShow 300ms ease;
}
@keyframes infoShow {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes infoHide {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

.news {
  background-color: rgba(183, 41, 40, 0.6);
  height: 40px;
  font-size: 10.5pt;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}

.news-wrapper {
  white-space: nowrap;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-right: 4px;
  overflow: hidden;
}

.news-title {
  background: url(../img/news.png) no-repeat;
  width: 23px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.news-content {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  width: calc(100% - 23px);
  color: #fff;
  min-width: initial;
}

.news-content p {
  line-height: 40px;
  font-size: 10.5pt;
  margin: 0;
  padding: 0 30px 0 10px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hot Lottery Section Starts Here */
.main-wrapper.hotlottery {
  width: 940px;
  max-width: initial;
  padding-top: 20px;
}

.hot-lottery {
  background-color: #c03939;
  height: 560px;
  background: url(../img/hotlottery_bg.jpg) no-repeat;
  background-position: center;
}

.subtitle {
  height: 122px;
  position: relative;
  text-align: center;
  background-color: #ffd5d0;
  background: url(../img/hl_header_bg.png) center no-repeat;
}
.subtitle label:before {
  content: "";
  background: url(../img/hotlottery_arrow.png) no-repeat;
  width: 331px;
  height: 14px;
  position: absolute;
  display: block;
  top: 27px;
  right: 65%;
}
.subtitle label:after {
  content: "";
  background: url(../img/hotlottery_arrow.png) no-repeat;
  width: 331px;
  height: 14px;
  position: absolute;
  display: block;
  top: 27px;
  left: 65%;
  transform: scaleX(-1);
}
.subtitle .ch {
  font-size: 18pt;
  color: #b62929;
  padding-top: 20px;
}
.subtitle .en {
  font-size: 10pt;
  color: #e45353;
  line-height: 24px;
}

.hl-box {
  width: 393px;
  position: relative;
  display: block;
  height: 370px;
  float: left;
  margin: 0 -40px;
  position: relative;
}
.hl-box:before {
  content: "";
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.hl-box:after {
  content: "";
  background-color: #f48c8c;
  transform: skew(-20deg);
  width: 60%;
  height: 86.5%;
  position: absolute;
  top: 17px;
  left: 90px;
  z-index: 0;
}
.hl-box:first-child:before {
  background-image: url(../img/hotlottery1.png);
}
.hl-box:nth-child(2):before {
  background-image: url(../img/hotlottery2.png);
}
.hl-box:last-child:before {
  background-image: url(../img/hotlottery3.png);
}

.hl-link {
  position: absolute;
  text-align: center;
  color: #fff;
  transform: translateX(-25%);
  margin: auto;
  bottom: 80px;
  font-size: 16pt;
  line-height: 28px;
  z-index: 2;
}
.hl-link.hl1 {
  left: 55%;
}
.hl-link.hl2 {
  left: 53%;
}
.hl-link.hl3 {
  left: 52%;
}
.hl-link:after {
  content: "";
  background-color: #eb2523;
  transform: skew(20deg);
  width: 160px;
  height: 45px;
  position: absolute;
  bottom: -9px;
  z-index: -1;
  box-shadow: 2px 2px 2px 0 rgba(30, 2, 39, 0.4);
}
.hl-link:hover:after {
  background-color: #961313;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
}
.hl-link.hl1:after {
  left: -51px;
}
.hl-link.hl2:after {
  left: -39px;
}
.hl-link.hl3:after {
  left: -30px;
}

.hot-color {
  background: url("../img/hotlottery_bg.jpg") center no-repeat #c03939;
}
.hot-color .hot-color-title {
  height: 125px;
  position: relative;
  text-align: center;
  background-color: #ffd5d0;
  background: url("../img/hl_header_bg.png") center no-repeat;
}
.hot-color .hot-color-title:before {
  content: "";
  background: url("../img/hotlottery_arrow.png") no-repeat;
  width: 331px;
  height: 14px;
  position: absolute;
  display: block;
  top: 27px;
  right: 65%;
}
.hot-color .hot-color-title:after {
  content: "";
  background: url("../../img/hotlottery_arrow.png") no-repeat;
  width: 331px;
  height: 14px;
  position: absolute;
  display: block;
  top: 27px;
  left: 65%;
  transform: scaleX(-1);
}
.hot-color .hot-color-title .cn {
  font-size: 18pt;
  color: #b62929;
  padding-top: 20px;
}
.hot-color .hot-color-title .en {
  font-size: 10pt;
  color: #e45353;
  line-height: 24px;
  text-transform: uppercase;
}
.hot-color .hot-color-game {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 50px 0;
  box-sizing: border-box;
}
.hot-color .hot-color-game.hide {
  display: none;
}
.hot-color .hot-color-game .game-box {
  border-radius: 25px;
  position: relative;
}
.hot-color .hot-color-game .game-box:not(:last-child) {
  margin-right: 20px;
}
.hot-color .hot-color-game .game-box:hover .text-box {
  background-color: #ffa00b;
  color: #fff;
}
.hot-color .hot-color-game .game-box:hover .text-box:before {
  background-color: #ffa00b;
  background-image: none;
  border-radius: 100%;
  top: -16px;
}
.hot-color .hot-color-game .game-box:hover .text-box:after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.hot-color .hot-color-game .game-box a {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.hot-color .hot-color-game .game-box .text-box {
  background-color: #fff;
  border-radius: 0 0 25px 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.hot-color .hot-color-game .game-box .text-box:before {
  content: "";
  background-image: url("../img/hot_shape.png");
  position: absolute;
  width: 100%;
  height: 30px;
  top: -26px;
  left: 0;
}
.hot-color .hot-color-game .game-box .text-box:after {
  content: "";
  height: 8px;
  width: 8px;
  border-bottom: 4px solid;
  border-bottom-color: transparent;
  border-right: 4px solid;
  border-right-color: transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(225deg);
}
.hot-color .hot-color-game .game-box .text-box .cn {
  font-size: 1.2rem;
  margin-bottom: 8px;
}
.hot-color .hot-color-game .game-box .text-box .eng {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.latest-hot {
  background: url("../img/latesthot_bg.png") center no-repeat #fff;
  padding: 25px 0;
}
.latest-hot .latest-hot-title {
  color: #b6b2b2;
  margin-bottom: 10px;
  text-align: center;
}
.latest-hot .latest-hot-title .en {
  position: relative;
  text-transform: uppercase;
  position: relative;
  margin-top: 5px;
}
.latest-hot .latest-hot-title .en:before, .latest-hot .latest-hot-title .en:after {
  background-color: #b6b2b2;
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  left: 45%;
  top: 6px;
}
.latest-hot .latest-hot-title .en:after {
  left: unset;
  right: 45%;
}
.latest-hot .latest-hot-gametype {
  display: flex;
  align-items: center;
  justify-content: center;
}
.latest-hot .latest-hot-gametype.hide {
  display: none;
}
.latest-hot .latest-hot-gametype .gametype-box {
  position: relative;
}
.latest-hot .latest-hot-gametype .gametype-box:not(:last-child) {
  margin-right: 20px;
}
.latest-hot .latest-hot-gametype .gametype-box a {
  position: absolute;
  height: 100%;
  width: 100%;
}
.latest-hot .latest-hot-desc {
  margin-top: 25px;
  text-align: center;
}
.latest-hot .latest-hot-desc .red {
  color: #c71f1e;
}

a {
  text-decoration: none;
}
a i {
  text-decoration: none;
  color: #e09e01;
}

p {
  margin: 0;
  padding: 0;
}

table,
th {
  border: none;
  padding: 10px;
}

table,
td {
  border: none;
  padding: 10px;
  background-color: none;
}

.main-wrapper {
  width: 1000px;
  max-width: 1048px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

/* Bottom 3 Section Starts Here */
.service-list {
  background: url(../img/service_bg.jpg) no-repeat;
  background-position: center;
  background-color: #f9ebeb;
}

.servicelist-wrapper {
  display: inline-block;
  padding: 35px 0;
  height: 318px;
  box-sizing: border-box;
  width: 100%;
}

.slist-box {
  width: calc((99% - 100px) / 3);
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 100px;
}
.slist-box:not(:last-child) {
  content: "";
  margin-right: 50px;
}

.sl-title {
  top: 0;
  display: block;
  height: 36px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f47170;
}
.sl-title .ch {
  font-size: 15pt;
  color: #eb2523;
  display: inline-block;
  font-weight: 600;
  border-bottom: 4px solid #eb2523;
  height: 34px;
}
.sl-title .en {
  font-size: 8pt;
  color: #f47170;
  display: inline-block;
}

/* Service Advantages Section Starts Here */
.sl1-title {
  top: 0;
  display: block;
  height: 40px;
  margin-bottom: 10px;
}

.sl1-deposit,
.sl1-withdraw {
  color: #717070;
  font-size: 10.5pt;
  width: 100%;
  display: block;
  margin-top: 30px;
  height: 80px;
}

.deposit-left {
  float: left;
  display: inline-block;
}

.deposit-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.dp-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.dp-title,
.wd-title {
  font-size: 11pt;
  margin: 2px 0;
  color: #827a7a;
}

.dp-time,
.wd-time {
  font-size: 9pt;
  color: #827a7a;
}

.dp-no,
.dp-sec {
  display: inline-block;
}

.dp-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.dp-bargrey {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  overflow: hidden;
}

.dp-barred {
  width: 0;
  height: 10px;
  border-radius: 20px;
  background-color: #eb2523;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
}
.dp-barred.active {
  width: 50%;
}

.withdraw-left {
  float: left;
  display: inline-block;
}

.withdraw-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.wd-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.wd-no,
.wd-sec {
  display: inline-block;
}

.wd-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.wd-bargrey {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  overflow: hidden;
}

.wd-barred {
  width: 0;
  height: 10px;
  border-radius: 20px;
  background-color: #ffa200;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
}
.wd-barred.active {
  width: 70%;
}

/* Mobile Terminal Downloads Starts Here */
.sl2-title {
  padding-bottom: 10px;
  color: #716f6f;
}

.sl2-text {
  font-size: 10.5pt;
  color: #717070;
  line-height: 17pt;
  margin-top: 30px;
}

.sl2-img {
  background: url(../img/terminal_download.png) no-repeat;
  width: 245px;
  height: 70px;
  margin-top: 50px;
  position: relative;
}

.appdl {
  width: 245px;
  height: 70px;
  position: absolute;
}

/* Help Center Section Starts Here */
.sl3-text-wrapper {
  display: block;
  font-size: 10pt;
  color: #717070;
  height: 100px;
  line-height: 16pt;
  margin-top: 30px;
}

.sl3-left {
  float: left;
  display: inline-block;
  width: 100px;
  padding-right: 30px;
}
.sl3-left a {
  display: block;
  padding-bottom: 20px;
  color: #717070;
}

.sl3-star {
  background: url(../img/star.png) no-repeat;
  height: 11px;
  width: 11px;
  display: inline-block;
  padding-right: 12px;
}

/* Homepage Footer Starts Here */
.foot {
  background-color: #191919;
  text-align: center;
  height: 227px;
  background-image: url(../img/footer_bg.jpg);
  background-repeat: repeat-x;
}

.footer {
  background-color: #000;
  height: 61px;
}

.foot-text1 {
  background: url(../img/providers.jpg) no-repeat;
  padding: 0;
  height: 100px;
  background-position: center;
  display: block;
}

.foot-text2 {
  color: #fff;
  font-size: 9.5pt;
  padding: 0;
  height: 75px;
  line-height: 66px;
  display: block;
}

.ft-browser {
  display: inline-block;
  font-size: 12pt;
}

.foot-text3 {
  color: #fff;
  font-size: 10pt;
  padding: 0;
  height: 50px;
  line-height: 46px;
  display: block;
}

.footer-text {
  background: url(../img/footer.jpg) no-repeat;
  padding: 0;
  height: 61px;
  background-position: center;
  display: block;
}

.ft-divider {
  background: url(../img/footer_divider.png) no-repeat;
  padding: 0;
  height: 1px;
  background-position: center;
  clear: both;
}

.ft-bs-img {
  background: url(../img/browser.png) no-repeat;
  width: 300px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

/* Subpage Layout Starts Here */
.subpage-body {
  background-color: #fff;
  height: auto;
  padding-bottom: 30px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}
.subpage-body p {
  color: #fff;
}

.sub-header {
  height: 140px;
  padding: 47px 0;
  box-sizing: border-box;
}

.sub-title,
.sub-desc {
  display: block;
}

.sub-content {
  height: auto;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.sub-content-wrapper {
  background-color: #fff;
  height: 360px;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
}

/* Register Starts Here */
.subpage-body.registration {
  background: url(../img/registration_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-content.registration {
  background-color: #faeeee;
  border: 1px solid #686868;
}

.sub-header.registration {
  height: 125px;
  width: 1000px;
  margin-bottom: 20px;
  padding: 0;
}

.reg-login-btn {
  display: inline-block;
  background: url(../img/button_reg.png) no-repeat;
  height: 113px;
  width: 209px;
  margin-top: 13px;
  vertical-align: middle;
  position: relative;
}
.reg-login-btn a {
  position: absolute;
  width: 97%;
  height: 95%;
  top: 6px;
}

.reg-banner {
  display: inline-block;
  background: url(../img/banner_reg.png) no-repeat;
  height: 125px;
  width: 786px;
  margin-top: 1px;
  vertical-align: middle;
}

.title-reg {
  font-size: 44pt;
  color: #a2a2a2;
  font-weight: 300;
  vertical-align: top;
  letter-spacing: 3px;
  display: inline-block;
}

.reg-steps {
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #efe8e8;
}
.reg-steps:after {
  content: "";
  display: table;
  clear: both;
}

.reg-steps-box {
  display: inline-block;
  vertical-align: middle;
  width: 33.3333333333%;
  text-align: center;
  border: 1px solid #c2c2c2;
  line-height: 32pt;
  float: left;
  box-sizing: border-box;
  position: relative;
}
.reg-steps-box.red {
  border: 1px solid #eeb958 !important;
  background: rgb(144, 19, 19);
  background: linear-gradient(to right, rgb(144, 19, 19) 0%, rgb(156, 46, 46) 100%);
}
.reg-steps-box:not(:first-child) {
  border-left: 0;
}
.reg-steps-box:not(:last-child) {
  border-left: 0;
}
.reg-steps-box:not(:last-child):before {
  content: "";
  width: 17px;
  height: 30px;
  position: absolute;
  background-color: #efe8e8;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 6px;
  right: -18px;
  border: 1px solid #c2c2c2;
  border-left: none;
}
.reg-steps-box:not(:last-child):after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid #978e8e;
  border-top: 6px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid transparent;
  top: 16px;
  right: -15px;
}
.reg-steps-box.red:not(:last-child):before {
  content: "";
  width: 17px;
  height: 30px;
  position: absolute;
  background-color: #9c2e2e;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 6px;
  right: -17px;
  border: 1px solid #eeb958;
  border-left: none;
}
.reg-steps-box.red:not(:last-child):after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid #fff;
  border-top: 6px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid transparent;
  top: 16px;
  right: -15px;
}

.steps-no {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: #978e8e;
}

.steps-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 10.5pt;
  color: #978e8e;
}

.steps-no.red,
.steps-title.red {
  color: #fff;
}

.reg-form {
  height: auto;
  padding: 40px 20px 0 20px;
}

.rform.row {
  height: 50px;
  line-height: 50px;
  padding-bottom: 5px;
}
.rform.col1 {
  width: 150px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  font-size: 9pt;
  color: #eb2523;
}
.rform.col2 {
  width: 280px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}
.rform.col2 input {
  box-sizing: border-box;
  width: 90%;
  height: 30px;
  line-height: 30px;
  background-color: transparent;
  -webkit-appearance: none;
  border-radius: 5px;
  outline: none;
  border: 1px solid #978e8e;
  padding-left: 5px;
}
.rform.col3 {
  width: calc(99% - 150px - 290px);
  display: inline-block;
  vertical-align: middle;
  font-size: 9pt;
}
.rform.row .button {
  width: 115px;
  left: 0;
  right: 0;
  margin: auto;
  height: 30px;
  font-size: 10pt;
  display: block;
  margin-top: 20px;
  border: 0px solid transparent;
  color: white;
  cursor: pointer;
}
.rform .verify-code {
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  bottom: 1px;
}

/* Promotion Starts Here */
.subpage-body.promo {
  min-height: calc(100vh - 48px - 71px - 490px - 227px - 61px);
  background: url(../img/promo_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-header.promo {
  height: 56px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
  padding: 0;
}

.sub-content.promo {
  background-color: rgba(0, 0, 0, 0.7);
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.promo-menu-links {
  color: #000;
  display: inline-block;
  width: 100px;
  height: 56px;
  position: relative;
  cursor: pointer;
}
.promo-menu-links:hover div {
  color: #fff;
}
.promo-menu-links.active div {
  color: #fff;
  pointer-events: none;
}
.promo-menu-links:before {
  content: "";
  background-color: #ce3333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1;
  opacity: 0;
  transform: skew(-20deg) translateX(-10%);
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
.promo-menu-links.active:before, .promo-menu-links:hover:before {
  opacity: 1;
  transform: skew(-20deg) translateX(0);
}

.promo-links {
  color: #b62929;
  font-size: 12.5pt;
  text-align: center;
  position: absolute;
  left: 18px;
  right: 0;
  line-height: 56px;
  z-index: 2;
}

.promo-menu-links div.active {
  pointer-events: none;
  color: #fff;
}
.promo-menu-links div:hover {
  color: #fff;
}

.promo-title {
  font-size: 24pt;
}

.promo-box {
  display: block;
  width: 100%;
  border: 1px solid #999999;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: white;
}
.promo-box .sub-content-item-header {
  min-height: 180px;
  max-height: 190px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.promo-box .sub-content-item-header h3 {
  margin: 10px 0;
}
.promo-box .sub-content-item-header .banner {
  width: 100%;
  height: auto;
}
.promo-box .sub-content-item-content {
  display: none;
}

.promo-menu {
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding: 0 20px;
}

/* FAQ Starts Here */
.sub-title.faq {
  background: url(../img/faq_title.png) no-repeat;
  width: 98px;
  height: 25px;
}

.sub-desc.faq {
  background: url(../img/faq_desc.png) no-repeat;
  width: 404px;
  height: 14px;
  margin-top: 7px;
}

.subpage-body.faq {
  min-height: calc(100vh - 48px - 71px - 227px - 61px);
  background: url(../img/faq_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-content.faq {
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 1px;
}

.sub-menu ul li {
  list-style: none;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  border-right: none;
}

.sub-body ul li {
  list-style: none;
}

.sub-content .sub-menu {
  float: left;
  width: 200px;
}
.sub-content .sub-menu .itms {
  width: 198px;
  height: 58px;
  border: 1px solid #fff;
  border-right: 1px solid #ffc600;
  position: relative;
  margin-bottom: -1px;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
}
.sub-content .sub-menu .itms.active {
  margin-top: 0;
  border: 1px solid #ffc600;
  border-right: 1px solid #391111;
  z-index: 9;
  color: #ffc600;
}

.sprite {
  background: url(../img/faq_sprite.png) no-repeat top left;
  width: 33px;
  height: 24px;
  float: left;
  margin-top: 15px;
  margin-left: 30px;
}

.itmstxt {
  font-size: 16px;
  float: left;
  line-height: 57px;
}

.sub-content .sub-menu .itms .icon1 {
  background-position: 0 0;
}
.sub-content .sub-menu .itms.active .icon1 {
  background-position: 0 -25px;
}
.sub-content .sub-menu .itms .icon2 {
  background-position: 0 -50px;
}
.sub-content .sub-menu .itms.active .icon2 {
  background-position: 0 -75px;
}
.sub-content .sub-menu .itms .icon3 {
  background-position: 0 -101px;
}
.sub-content .sub-menu .itms.active .icon3 {
  background-position: 0 -125px;
}
.sub-content .sub-menu .itms .icon4 {
  background-position: 0 -150px;
}
.sub-content .sub-menu .itms.active .icon4 {
  background-position: 0 -175px;
}
.sub-content .sub-menu .itms .icon5 {
  background-position: 0 -200px;
}
.sub-content .sub-menu .itms.active .icon5 {
  background-position: 0 -225px;
}
.sub-content .sub-menu .itms .icon6 {
  background-position: 0 -250px;
}
.sub-content .sub-menu .itms.active .icon6 {
  background-position: 0 -275px;
}
.sub-content .sub-body {
  float: left;
  width: calc(100% - 201px);
  margin-left: -3px;
  border: 1px solid #ffc600;
  color: #fff;
  display: none;
  min-height: 412px;
}

.faq-title {
  font-size: 19pt;
  font-weight: 600;
  padding: 18px 35px 0;
}

.faq-text {
  font-size: 11pt;
  line-height: 20pt;
  padding-bottom: 3px;
}

/* Mobile Betting Page Starts Here */
.subpage-body.mobilebet {
  background: url(../img/mobilebet_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 0;
}

.sub-content.mobilebet {
  background-color: transparent;
  height: 800px;
  position: relative;
  overflow: auto;
  padding: 40px 0 0 0;
  box-sizing: border-box;
}

.left-col1 {
  background: url(../img/mobile.png) no-repeat;
  width: 534px;
  height: 661px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-top: 80px;
  position: absolute;
  left: -80px;
}

.right-col1 {
  float: right;
  width: 530px;
  display: inline-block;
  vertical-align: middle;
  height: 620px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  margin-top: 60px;
  position: relative;
}

.play-icon {
  background: url(../img/play.gif) no-repeat;
  width: 84px;
  height: 84px;
  left: -40px;
  top: -28px;
  position: absolute;
}

.btn-play {
  width: 84px;
  height: 84px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.mobile-title {
  background: url(../img/mb_title.png) no-repeat;
  width: 421px;
  height: 28px;
  display: inline-block;
}

.right-col1-text2 {
  font-size: 11pt;
  margin: 5px 0;
}

.right-col1-img {
  background: url(../img/mb_advantages.png) no-repeat;
  width: 507px;
  height: 136px;
  margin: 30px 20px;
}

.right-col1-text3 {
  font-size: 12.5pt;
  padding-bottom: 25px;
  position: relative;
}
.right-col1-text3 label:before {
  content: "";
  position: absolute;
  display: block;
  width: 82px;
  border-top: 1px solid #fff;
  top: 12px;
  left: 13%;
}
.right-col1-text3 label:after {
  content: "";
  position: absolute;
  display: block;
  width: 82px;
  border-top: 1px solid #fff;
  top: 12px;
  left: 64%;
}

.qrcode-wrapper {
  margin: 20px 0;
}

.right-col1-wrapper {
  display: inline-block;
  margin: 0 10px;
}

.right-col1-android {
  display: inline-block;
  background: url(../img/mb_60cpapp.png);
  width: 192px;
  height: 193px;
}
.right-col1-android img,
.right-col1-android canvas {
  width: 170px;
  position: absolute;
  top: 97px;
  height: 166px;
  left: 72px;
}

.tx-android,
.tx-ios {
  color: #fff;
  display: block;
  font-size: 11pt;
  margin: 10px;
}

.btn-android {
  background: url(../img/mb_button.png) 0 0;
  width: 180px;
  height: 56px;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
}

.btn-ios {
  background: url(../img/mb_button.png) 180px 0;
  width: 180px;
  height: 56px;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
}

.btn-qr {
  width: 180px;
  height: 56px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.right-col1-ios {
  display: inline-block;
  background: url(../img/mb_60cpapp.png);
  width: 192px;
  height: 193px;
}
.right-col1-ios img,
.right-col1-ios canvas {
  width: 170px;
  position: absolute;
  top: 97px;
  height: 166px;
  right: 72px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: none;
  z-index: 999;
}
.overlay:target {
  display: block;
}

.popup {
  margin: 80px auto;
  padding: 20px;
  background: #000;
  width: 70%;
  position: relative;
  transition: all 5s ease-in-out;
  margin: 80px auto;
  padding: 20px;
  background: #000;
  width: 70%;
  height: calc(100% - 200px);
  position: relative;
  transition: all 5s ease-in-out;
}
.popup .close {
  position: absolute;
  top: 12px;
  right: 12px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  z-index: 1;
}
.popup .close:hover {
  color: #ce3333;
}
.popup .content {
  text-align: center;
}

.content .video-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.popup img {
  max-width: 100%;
}

.video-js .vjs-big-play-button {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto;
}

/* Jssor Slider */
/* ssor slider loading skin spin css */
.jssorb032 {
  position: absolute;
}
.jssorb032 .i {
  position: absolute;
  cursor: pointer;
}
.jssorb032 .i .b {
  fill: #fff558;
}
.jssorb032 .i:hover .b {
  fill: #f45b25;
  fill-opacity: 0.6;
}
.jssorb032 .iav .b {
  fill: #f45b25;
  fill-opacity: 1;
}
.jssorb032 .i.idn {
  opacity: 0.3;
}

.jssora051 {
  display: block;
  position: absolute;
  cursor: pointer;
}
.jssora051 .a {
  fill: none;
  stroke: #fff;
  stroke-width: 360;
  stroke-miterlimit: 10;
}
.jssora051:hover {
  opacity: 0.8;
}
.jssora051.jssora051dn {
  opacity: 0.5;
}
.jssora051.jssora051ds {
  opacity: 0.3;
  pointer-events: none;
}

.daohang {
  width: 120px;
}

.appqr-wrapper {
  z-index: 99999;
  width: 118px;
}
.appqr-wrapper .appqrcode {
  width: 118px;
  background-color: #b62929;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.appqr-wrapper .appqrcode img {
  width: 98px;
  height: 98px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 6px;
}

.appqr img {
  background-color: #fff;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  top: 80px;
  position: absolute;
  left: 14px;
}

.appqr-wrapper .text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  font-style: italic;
  margin-top: 7px;
  font-weight: 600;
}

.appdlurl {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.5px;
}