/* banner */

.bannerpromo {
  background-image: url(../img/cash/promotiontile.jpg);
  background-repeat: repeat-x;
}

.promobanner {
  cursor: pointer;
}

.bxslider2,
.bxslider3 {
  height: 160px !important;
}

.coltxt a {
  color: #000;
}

.cci_title {
  height: 20px;
  left: 0px;
  top: 0px;
  color: #111;
  font-size: 15.5px;
  line-height: 18px;
  white-space: nowrap;
  text-shadow: 0 1px 0 #ffffff;
}

.cci_title2 {
  height: auto;
  left: 0px;
  top: 0px;
  color: #111;
  font-size: 15.5px;
  line-height: 20px;
  white-space: nowrap;
  text-shadow: 0 1px 0 #ffffff;
  margin-bottom: 10px;
}

.bxslider4 {
  height: 274px !important;
}

/*Main Banner Starts Here*/

.main-banner {
  height: 490px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 490px;
    .swiper-pagination.banner {
      bottom: 50px;
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
        background-color: #fec52e;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  overflow: hidden;
  .img-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(10px) grayscale(50%);
    z-index: 0;
  }
  img {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}

/* News Section Starts Here */
.marquee-wrapper {
  display: flex;
  align-items: center;
  .info-wrapper {
    width: 100%;
    height: 2.75rem;
    position: relative;
    overflow: hidden;
    .info {
      padding-left: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      animation: infoHide 300ms ease;
      animation-fill-mode: both;
      &.active {
        animation: infoShow 300ms ease;
      }
    }
    @keyframes infoShow {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes infoHide {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(-100%);
      }
    }
  }
}

.news {
  background-color: rgba(183, 41, 40, 0.6);
  height: 40px;
  font-size: 10.5pt;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}

.news-wrapper {
  white-space: nowrap;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-right: 4px;
  overflow: hidden;
}

.news-title {
  background: url(../img/news.png) no-repeat;
  width: 23px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.news-content {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  width: calc(100% - 23px);
  color: #fff;
  min-width: initial;
}

.news-content p {
  line-height: 40px;
  font-size: 10.5pt;
  margin: 0;
  padding: 0 30px 0 10px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hot Lottery Section Starts Here */

.main-wrapper.hotlottery {
  width: 940px;
  max-width: initial;
  padding-top: 20px;
}

.hot-lottery {
  background-color: #c03939;
  height: 560px;
  background: url(../img/hotlottery_bg.jpg) no-repeat;
  background-position: center;
}

.subtitle {
  height: 122px;
  position: relative;
  text-align: center;
  background-color: #ffd5d0;
  background: url(../img/hl_header_bg.png) center no-repeat;
  label {
    &:before {
      content: "";
      background: url(../img/hotlottery_arrow.png) no-repeat;
      width: 331px;
      height: 14px;
      position: absolute;
      display: block;
      top: 27px;
      right: 65%;
    }
    &:after {
      content: "";
      background: url(../img/hotlottery_arrow.png) no-repeat;
      width: 331px;
      height: 14px;
      position: absolute;
      display: block;
      top: 27px;
      left: 65%;
      transform: scaleX(-1);
    }
  }
  .ch {
    font-size: 18pt;
    color: #b62929;
    padding-top: 20px;
  }
  .en {
    font-size: 10pt;
    color: #e45353;
    line-height: 24px;
  }
}

.hl-box {
  width: 393px;
  position: relative;
  display: block;
  height: 370px;
  float: left;
  margin: 0 -40px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    background-color: #f48c8c;
    transform: skew(-20deg);
    width: 60%;
    height: 86.5%;
    position: absolute;
    top: 17px;
    left: 90px;
    z-index: 0;
  }
  &:first-child:before {
    background-image: url(../img/hotlottery1.png);
  }
  &:nth-child(2):before {
    background-image: url(../img/hotlottery2.png);
  }
  &:last-child:before {
    background-image: url(../img/hotlottery3.png);
  }
}

.hl-link {
  position: absolute;
  text-align: center;
  color: #fff;
  transform: translateX(-25%);
  margin: auto;
  bottom: 80px;
  font-size: 16pt;
  line-height: 28px;
  z-index: 2;
  &.hl1 {
    left: 55%;
  }
  &.hl2 {
    left: 53%;
  }
  &.hl3 {
    left: 52%;
  }
  &:after {
    content: "";
    background-color: #eb2523;
    transform: skew(20deg);
    width: 160px;
    height: 45px;
    position: absolute;
    bottom: -9px;
    z-index: -1;
    box-shadow: 2px 2px 2px 0 rgba(30, 2, 39, 0.4);
  }
  &:hover:after {
    background-color: #961313;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
  }
  &.hl1:after {
    left: -51px;
  }
  &.hl2:after {
    left: -39px;
  }
  &.hl3:after {
    left: -30px;
  }
}



.hot-color {
  background: url('../img/hotlottery_bg.jpg') center no-repeat #c03939;

  .hot-color-title {
    height: 125px;
    position: relative;
    text-align: center;
    background-color: #ffd5d0;
    background: url('../img/hl_header_bg.png') center no-repeat;

    &:before {
      content: "";
      background: url('../img/hotlottery_arrow.png') no-repeat;
      width: 331px;
      height: 14px;
      position: absolute;
      display: block;
      top: 27px;
      right: 65%;
    }
    &:after {
      content: "";
      background: url('../../img/hotlottery_arrow.png') no-repeat;
      width: 331px;
      height: 14px;
      position: absolute;
      display: block;
      top: 27px;
      left: 65%;
      transform: scaleX(-1);
    }

    .cn {
      font-size: 18pt;
      color: #b62929;
      padding-top: 20px;
    }
    .en {
      font-size: 10pt;
      color: #e45353;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .hot-color-game {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 50px 0;
    box-sizing: border-box;
    &.hide {
      display: none;
    }

    .game-box {
      border-radius: 25px;
      position: relative;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        .text-box {
          background-color: #ffa00b;
          color: #fff;

          &:before {
            background-color: #ffa00b;
            background-image: none;
            border-radius: 100%;
            top: -16px;
          }
          &:after {
            border-bottom-color: #fff;
            border-right-color: #fff;
          }
        }
      }
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      .text-box {
        background-color: #fff;
        border-radius: 0 0 25px 25px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 76px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 0;

        &:before {
          content: "";
          background-image: url("../img/hot_shape.png");
          position: absolute;
          width: 100%;
          height: 30px;
          top: -26px;
          left: 0;
        }

        &:after {
          content: "";
          height: 8px;
          width: 8px;
          border-bottom: 4px solid;
          border-bottom-color: transparent;
          border-right: 4px solid;
          border-right-color: transparent;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) rotate(225deg);
        }

        .cn {
          font-size: 1.2rem;
          margin-bottom: 8px;
        }
        .eng {
          font-size: .75rem;
          text-transform: uppercase;
        }
      }
    }
  }
}

.latest-hot {
  background: url('../img/latesthot_bg.png') center no-repeat #fff;
  padding: 25px 0;
  .latest-hot-title {
    color: #b6b2b2;
    margin-bottom: 10px;
    text-align: center;
    .en {
      position: relative;
      text-transform: uppercase;
      position: relative;
      margin-top: 5px;
      &:before, &:after {
        background-color: #b6b2b2;
        content: "";
        position: absolute;
        width: 30px;
        height: 1px;
        left: 45%;
        top: 6px;
      }
      &:after {
        left: unset;
        right: 45%;
      }
    }
  }

  .latest-hot-gametype {
    display: flex;
    align-items: center;
    justify-content: center;
    &.hide {
      display: none;
    }
    .gametype-box {
      position: relative;
      &:not(:last-child) {
        margin-right: 20px;
      }
      a {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }

  .latest-hot-desc {
    margin-top: 25px;
    text-align: center;
    .red {
      color: #c71f1e;
    }
  }
}
