/* Floater Starts Here */

.socialnavi {
  position: fixed;
  right: 10px;
  top: 250px;
  overflow: visible;
  z-index: 10;
}

.qq,
.wechat,
.phone,
.livechat,
.chatroom,
.download,
.phoneback,
.download-mac,
.download-windows,
.morse,
.kefuapp {
  border-radius: 38px;
  height: 50px;
  width: 50px;
  margin-bottom: 3px;
  cursor: pointer;
}

.qq {
  background: url(../img/icons/support_qq.png) center;
  background-color: #0099cc;
  &:hover {
    border: 3px solid #7ddfff;
    height: 44px;
    width: 44px;
  }
}

.wechat {
  position: relative;
  background: url(../img/icons/support_wechat.png) center;
  background-color: #009900;
  &:hover {
    border: 3px solid #9afa9a;
    height: 44px;
    width: 44px;
    .sidebar-popout {
      transform: scale(1);
    }
  }
}

.downloadqr {
  background-image: url(../img/icons/download_bg.png);
  position: absolute;
  top: 160px;
  left: -160px;
  width: 158px;
  height: 61px;
  display: none;
  p {
    margin-top: 13px;
    text-align: center;
    line-height: 17px;
  }
}

.phoneback {
  background: url(../img/icons/phoneback.png) center;
  background-color: #0099cc;
  &:hover {
    border: 3px solid #7ddfff;
    height: 46px;
    width: 46px;
  }
}

.phonebackqr {
  background-image: url(../img/icons/download_bg.png);
  position: absolute;
  top: 210px;
  left: -160px;
  width: 158px;
  height: 61px;
  display: none;
  p {
    margin-top: 20px;
    text-align: center;
    line-height: 17px;
  }
}

.phone {
  background: url(../img/icons/support_call.png) center;
  background-color: #f0664b;
  &:hover {
    border: 3px solid #ffc8aa;
    height: 44px;
    width: 44px;
  }
}

.livechat {
  background: url(../img/icons/livechat.png) center;
  background-color: #ffae00;
  &:hover {
    border: 3px solid #ffc8aa;
    height: 44px;
    width: 44px;
  }
}

.chatroom {
  background: url(../img/icons/chatroom.png) center;
  background-color: #feb6a7;
  &:hover {
    border: 3px solid #fecac0;
    height: 44px;
    width: 44px;
  }
}

.download {
  background: url(../img/icons/download.png) center;
  background-color: #536bb9;
  &:hover {
    border: 3px solid #ffc8aa;
    height: 44px;
    width: 44px;
  }
}

.download-mac {
  background: #ffb82e url(../img/icons/mac_download.png) center;
  position: relative;
}

.download-windows {
  background: #ffb82e url(../img/icons/windows_download.png) center;
  position: relative;
}

.download-mac:before,
.download-windows:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  opacity: 0;
}

.download-mac:hover:before,
.download-windows:hover:before {
  opacity: 1;
}

.morse {
  display: block;
  position: relative;
  background: url(../img/morse-icon.png) center / contain no-repeat;
  &:hover {
    border: 3px solid rgba(255, 255, 255, 0.5);
    height: 44px;
    width: 44px;
    .morseqr {
      display: block;
    }
  }
}

.morseqr {
  display: none;
  position: absolute;
  height: 165px;
  width: 186px;
  top: 0;
  left: -170px;
}

.specdetail .card_count {
  margin-top: 0px;
}

.kefuapp {
  position: relative;
  background: url(../img/icons/kefuapp.png) center / 75% #b75b1f no-repeat;
  border: 3px solid transparent;
  height: 44px;
  width: 44px;
  &:hover {
    border-color: #823a0b;
    .sidebar-popout {
      transform: scale(1);
    }
  }
}

.sidebar-popout {
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  color: #fff;
  padding: 5px;
  position: absolute;
  right: 115%;
  top: 0;
  text-align: center;
  transition: all 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
  transform: scale(0);
  transform-origin: top right;
}

/* left adv */

.left_adv {
  height: 389px;
  width: 129px;
  position: fixed;
  left: 0;
  top: 220px;
  z-index: 9;
}

.left_adv_img {
  width: 100%;
}

/* right adv */

.rigth_adv {
  height: 250px;
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 999;
  img {
    width: 127px;
  }
}
