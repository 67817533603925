/* footer navi */

.footercontainer {
  width: 1000px;
  height: 238px;
  margin: 0 auto;
}

.footernavi2 {
  height: 120px;
  background-color: #2161b3;
  .col4,
  .col5,
  .col6 {
    height: 238px;
    float: left;
  }
  .col4 {
    width: 325px;
  }
  .col5 {
    width: 338px;
  }
  .col6 {
    width: 331px;
  }
}

.footercontent {
  width: 220px;
  height: auto;
  margin-top: 15px;
  margin-left: 85px;
  text-align: left;
  a {
    text-decoration: none;
    color: #bcc9e3;
    line-height: 25px;
    font-size: 13px;
  }
}

.borderright {
  border-right: 1px solid #174f97;
}

.borderleft {
  border-left: 1px solid #4073b4;
}

.bothborder {
  border-left: 1px solid #174f97;
  border-right: 1px solid #4073b4;
}

.footerindex2 {
  padding: 30px 0;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.bank_number {
  margin-top: 0px;
}

.bank_numbertxt {
  margin-top: 26px;
}

#btn-register {
  float: left;
  margin-right: 5px;
  margin-top: 1px;
}
